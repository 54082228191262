import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import ColumnRangeChart from "./ColumnRangeChart";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "bar-chart"
    }}>{`Bar Chart`}</h1>
    <h2 {...{
      "id": "attributes"
    }}>{`Attributes`}</h2>
    <Props of={ColumnRangeChart} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'<ColumnRangeChart\n  id=\"premium-ranges-by-category\"\n  year={2019}\n  state=\"Texas\"\n  title=\"Comparison of Premium Ranges\"\n  horizontal\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ColumnRangeChart,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ColumnRangeChart id='premium-ranges-by-category' year={2019} state='Texas' title='Comparison of Premium Ranges' horizontal mdxType="ColumnRangeChart" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      